import React, { Component } from 'react';
import styles from './NavBar.module.css';
import {
  Link
} from "react-router-dom";
import styled from 'styled-components';

class NavBar extends Component {
  constructor(props){
    super();
  }

  render() {
    return <div id={styles.nav}>
      <NavList>
        {this.props.refreshToken 
          ? <li><Link to="/portfolio">Portfolio</Link></li>
          : <li><Link style={{background: "#212121", color:"#6f6f6f", cursor:"default"}}>Portfolio</Link></li>
        }
        <li><Link to="/">Cards</Link></li>
        <li><Link to="/learn">Learn</Link></li>
      </NavList>
    </div>
  }
}

const NavList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333; 
`

export default NavBar;