import React, { useState } from 'react';
import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Login from '../Login/Login';
import Title from '../Title/Title';
import NavBar from '../NavBar/NavBar';
import Search from '../Search/Search';
import Learn from '../Learn/Learn';
import Footer from '../Footer/Footer';
import CardDetails from '../CardDetails/CardDetails';
import Portfolio from '../Portfolio/Portfolio';
import Cookies from 'universal-cookie';

function Index() {
    const cookie = new Cookies();
    const [token, setToken] = useState(cookie.get('token'));
    const [refreshToken, setRefreshToken] = useState(cookie.get('refreshToken'));

    return (
        <div>
            <Login 
                token={token}
                refreshToken={refreshToken}
                setToken={setToken}
                setRefreshToken={setRefreshToken}
            />
            <Title />
            <Router>
                <NavBar
                    refreshToken={refreshToken}
                />
                <Switch>
                    <Route exact path="/learn" component={Learn} />
                    <Route exact path="/carddetail/:id">
                        <CardDetails
                            token={token}
                        />
                    </Route>
                    <Route exact path="/" component={Search} />
                    <Route exact path="/portfolio">
                        <Portfolio 
                            token={token}
                        />    
                    </Route>
                </Switch>
            </Router>
            <Footer />
        </div>
    )

}

export default Index;