
import React, { Component } from 'react';
import './Login.css';
import Tooltip from '../Common/Tooltip';
import styled from 'styled-components';
import Cookies from 'universal-cookie';

class Login extends Component {

	constructor(props) {
		super(props);
		console.log("Rerender login")

		this.state = {
			process: 'login',
			tooltip: false,
			modal: false,
			password: '',
			password2: '',
			loginState: props.token && props.refreshToken ? 0 : -1,
			email: ''
		}

		this.modalRef = React.createRef();
		this.tooltipRef = React.createRef();

		this.state.toolTipProps = [
			{
				exp: /[\w\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}/,
				text: "8 Characters"
			},
			{
				exp: /[a-z]+/,
				text: "1 Lower Case"
			},
			{
				exp: /[A-Z]+/,
				text: "1 Upper Case"
			},
			{
				exp: /[\d]+/,
				text: "1 Digit"
			},
		]
	}

	login = () => {
		let em = document.getElementById("email");
		let pw = document.getElementById("password");
		if ((em && pw) && (em.value && pw.value)) {
			let obj = {};
			obj.email = em.value;
			obj.password = pw.value;
			fetch("/api/v1/Login", {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(obj),
			})
			.then((response) => {
				return response.json();
			}).then((res) => {
				let data = res;
				console.log(data)
				if (res) {
					this.setState({
						modal: false,
						token: data.accessToken,
						refreshToken: data.refreshToken,
						loginState: data.level,
						email: em.value
					})
					document.cookie = `token=${data.accessToken}`;
					document.cookie = `refreshToken=${data.refreshToken}`
					this.props.setToken(data.accessToken)
					this.props.setRefreshToken(data.refreshToken)
					console.log("This is the cookie", document.cookie);
				}
				else {
					console.log("failed to login")
				}
			}).catch((err) => {
				console.log(err);
			});
		}
	}

	register = () => {
		let em = document.getElementById("email");
		let pw = document.getElementById("password");
		let pw2 = document.getElementById("password2");
		console.log(this.state.password)
		if (this.state.email && this.state.password && (this.state.password === this.state.password2)) {
			let obj = {};
			obj.email = em.value;
			obj.password = pw.value;

			fetch("/api/v1/Registration", {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(obj),
			})
				.then((response) => {
					return response;
				}).then((res) => {
					let status = res.status;
					let data = res.json();
					if (status === 200) {
						this.setState({
							modal: false,
							loginState: data.level
						})
					}
					else {
						console.log("failed to login")
					}
				}).catch((err) => {
					console.log(err);
				});
		}
	}

	logout = () => {

		fetch("/api/v1/Logout", {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ token: this.refreshToken}),
		})
		.then((response) => {
			return response;
		}).then((res) => {
			let status = res.status;
			if (status === 200) {
				let cookie = new Cookies();
				cookie.remove('token');
				cookie.remove('refreshToken');
				this.props.setToken(null)
				this.props.setRefreshToken(null)
				this.setState({
					loginState: -1,
					email: null
				})
				
			}
			else {
				console.log("failed to logout")
			}
		}).catch((err) => {
			console.log(err);
		});
	}

	loginModal = () => {
		return <ModalContents>
			<Close onClick={() => this.setState({modal: false})}>X</Close>
			<CenterDiv>Log In</CenterDiv>
			<Input id="email" placeholder="Email" />
			<Input type="password" id="password" placeholder="Password" />
			<UserLogin id="login" onClick={this.login}>Sign in</UserLogin>
			Not a member?
			<Button onClick={() => { this.setState({ process: "registration" }) }}>Register</Button>
		</ModalContents> 
	}

	registrationModal = () => {
		return <ModalContents>
			<Close onClick={() => this.setState({modal: false})}>X</Close>
			<CenterDiv>Registration</CenterDiv>
			<Input id="email" placeholder="Email" />
			<div>
				<Input type="password" id="password" placeholder="Password" 
				value={this.password} onChange={(event) => this.setState({password: event.target.value})} onFocus={() => {this.setState({ tooltip: true })}} onBlur={() => {this.setState({ tooltip: false })}}/>
				<Tooltip tooltip={this.state.tooltip} password={this.state.password} pr={this.state.toolTipProps}></Tooltip>
			</div>
			<Input type="password" id="password2" placeholder="Retype Password" 
			value={this.password2} onChange={(event) => this.setState({password2: event.target.value})}/>
			<UserLogin id="login" onClick={this.register}>Register</UserLogin>
			<Button onClick={() => { this.setState({ process: "login" }) }}>Cancel</Button>
		</ModalContents>
	}

	render() {

		let modal = this.state.process === "login" ? this.loginModal() : this.registrationModal()
		
		if (this.state.loginState === -1) {
			return <div id="sign_in">
				<Button onClick={() => this.setState({modal: true})}>Log In</Button>				
				<Modal modal={this.state.modal} ref={this.modalRef}>
					{modal}
				</Modal>
			</div>
		}
		else {
			return <div id="sign_in">
				Welcome, {this.state.email || "Dude"} <Button onClick={() => this.logout()}>Logout</Button >
			</div>
		}
	}
}

const CenterDiv = styled.h2`
	text-align: center;
`

const Modal = styled.div`
	left: 50%;
	position: fixed;
	background: black;
	z-index: 1;
	border: 1rem solid white;
	border-radius: 1rem;
	box-shadow: 10px 10px #0000008c;
	transition: top 0.7s;
	transform: translate(-50%, -50%);
	top: ${props =>  props.modal ? "50%" : "-50%"};
`

const Button = styled.button`
	background: transparent;
	color: white;
	border: 2px solid white;
    font-family: monospace;
`

const Input = styled.input`
	display:block;
	margin: 0.5rem
`

const UserLogin = styled(Button)`
	display: block;
	margin: 0.5rem
`

const Close = styled(Button)`
    top: 0%;
    right: 0%;
	position: absolute;
	border: none;
	display: block;
	font-size: xx-large;
	cursor: pointer;
`

const ModalContents = styled.div`
	margin: 5rem;
`

export default Login;