import React, { Component } from 'react';
import styles from './Search.module.css';
import SearchResults from '../SearchResults/SearchResults'
import { Multiselect } from 'multiselect-react-dropdown'
import styled from "styled-components"
import { setOptions, typeOptions, elementOptions, rarityOptions, artistOptions, abilityOptions } from './SearchOptions'

const searchElement = {
    Name: "name",
    Power: "power",
    Set: "set",
    Type: "type",
    Job: "job",
    Element: "element",
    Cost: "cost",
    Rarity: "rarity",
    Category: "category",
    Artist: "artist",
    Ability: "ability"
}

const initialState = {
    docNum: 0,
    cards: [],
    query: {},
    search: {},
    name: "",
    set: [],
    power: "",
    type: [],
    job: "",
    element: [],
    cost: "",
    rarity: [],
    category: "",
    artist: [],
    ability: [],
    setRef: React.createRef(),
    typeRef: React.createRef(),
    elementRef: React.createRef(),
    rarityRef: React.createRef(),
    artistRef: React.createRef(),
    abilityRef: React.createRef()
};
class Search extends Component {

    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount(){
        this.setRef = React.createRef();
        this.typeRef = React.createRef();
        this.elementRef = React.createRef();
        this.rarityRef = React.createRef();
        this.artistRef = React.createRef();
        this.abilityRef = React.createRef();
    }

    submitForm = (event) => {
        let payload = constructQuery(this.state);
        if(!payload){
            return
        }
        fetch('/api/v1/postCard', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    cards: data.docs,
                    docNum: data.docNum
                })
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    pagerClick = event => {
        let { value } = event.target
        let payload = constructQuery(this.state);
        if(!payload){
            return
        }
        payload["page"] = value;

        fetch('/api/v1/postCard', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    cards: data.docs,
                    docNum: data.docNum
                })
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    clearForm = event => {
        this.state.setRef.current.resetSelectedValues();
        this.state.typeRef.current.resetSelectedValues();
        this.state.elementRef.current.resetSelectedValues();
        this.state.rarityRef.current.resetSelectedValues();
        this.state.artistRef.current.resetSelectedValues();
        this.state.abilityRef.current.resetSelectedValues();

        this.setState(initialState);

        let els = document.getElementsByName("category");

        els.forEach(e => {
            e.value = "";
        });
    }

    handleChangeCheckBox = event => {
        const { name, checked } = event.target

        this.setState({
            [name]: checked,
        })
    }

    setSearchState = (key, value) => {
        let obj = this.props.search;
        obj[key] = value
        this.props.setSearch(obj)
    }

    style = {
        searchBox: {
            fontSize: "15px",
            background: "white",
            height: "15px"
        },
        inputField: {
            margin: "auto"
        },
        chips: {
            background: "#4f8a4f"
        },
    }

    setOnSelect = (selectedList, selectedItem) => {
        console.log(this)
        console.log(selectedList)
        console.log(selectedItem)

        let ar = selectedList.map(x => { return x.id; })

        this.setState({
            [searchElement.Set]: ar
        });
    }

    setOnRemove = (selectedList, selectedItem) => {
        let ar = selectedList.map(x => { return x.id; })

        this.setState({
            [searchElement.Set]: ar
        });
    }

    typeSelectedValue = []

    typeOnSelect = (selectedList, selectedItem) => {
        let ar = selectedList.map(x => { return x.id; })

        this.setState({
            [searchElement.Type]: ar
        });
    }

    typeOnRemove = (selectedList, selectedItem) => {
        let ar = selectedList.map(x => { return x.id; })

        this.setState({
            [searchElement.Type]: ar
        });
    }

    elementSelectedValue = []

    elementOnSelect = (selectedList, selectedItem) => {
        let ar = selectedList.map(x => { return x.id; })

        this.setState({
            [searchElement.Element]: ar
        });
    }

    elementOnRemove = (selectedList, selectedItem) => {
        let ar = selectedList.map(x => { return x.id; })

        this.setState({
            [searchElement.Element]: ar
        });
    }

    raritySelectedValue = []

    rarityOnSelect = (selectedList, selectedItem) => {
        let ar = selectedList.map(x => { return x.id; })

        this.setState({
            [searchElement.Rarity]: ar
        });
    }

    rarityOnRemove = (selectedList, selectedItem) => {
        let ar = selectedList.map(x => { return x.id; })

        this.setState({
            [searchElement.Rarity]: ar
        });
    }

    artistSelectedValue = []

    artistOnSelect = (selectedList, selectedItem) => {
        let ar = selectedList.map(x => { return x.id; })

        this.setState({
            [searchElement.Artist]: ar
        });
    }

    artistOnRemove = (selectedList, selectedItem) => {
        let ar = selectedList.map(x => { return x.id; })

        this.setState({
            [searchElement.Artist]: ar
        });
    }

    abilitySelectedValue = []

    abilityOnSelect = (selectedList, selectedItem) => {
        let ar = selectedList.map(x => { return x.id; })

        this.setState({
            [searchElement.Ability]: ar
        });
    }

    abilityOnRemove = (selectedList, selectedItem) => {
        let ar = selectedList.map(x => { return x.id; })

        this.setState({
            [searchElement.Ability]: ar
        });
    }

    formChange = (e) => {
        let value = e.target.value;
        let name = e.target.id;
        this.setState({
            [name]: value
        })
    }

    render() {
        return <div id={styles.search}>
            <div className={styles.grid}>
                <div className={styles.gridItem}>
                    <h4>Name</h4>
                    <label>
                        <Input type="text" id="name" name="category" value={this.name} onChange={this.formChange} placeholder="Auron" />
                    </label>
                </div>
                <div className={styles.gridItem}>
                    <h4>Set</h4>
                    <label>
                        <Multiselect
                            options={setOptions} // Options to display in the dropdown
                            selectedValues={this.setSelectedValue2} // Preselected value to persist in dropdown
                            onSelect={this.setOnSelect} // Function will trigger on select event
                            onRemove={this.setOnRemove} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            closeIcon="cancel"
                            style={this.style}
                            ref={this.state.setRef}
                        />
                    </label>
                </div>
                <div className={styles.gridItem}>
                    <h4>Power</h4>
                    <Input type="number" step="1000" id="power" name="category" value={this.power} onChange={this.formChange} min="0" max="10000" />
                </div>
                <div className={styles.gridItem}>
                    <h4>Type</h4>
                    <label>
                        <Multiselect
                            options={typeOptions} // Options to display in the dropdown
                            selectedValues={this.typeSelectedValue} // Preselected value to persist in dropdown
                            onSelect={this.typeOnSelect} // Function will trigger on select event
                            onRemove={this.typeOnRemove} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            closeIcon="cancel"
                            style={this.style}
                            ref={this.state.typeRef}
                        />
                    </label>
                </div>
                <div className={styles.gridItem}>
                    <h4>Job</h4>
                    <Input type="text" id="job" name="category" value={this.job} onChange={this.formChange} placeholder="Guardian" />
                </div>
                <div className={styles.gridItem}>
                    <h4>Element</h4>
                    <label>
                        <Multiselect
                            options={elementOptions} // Options to display in the dropdown
                            selectedValues={this.elementSelectedValue} // Preselected value to persist in dropdown
                            onSelect={this.elementOnSelect} // Function will trigger on select event
                            onRemove={this.elementOnRemove} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            closeIcon="cancel"
                            style={this.style}
                            ref={this.state.elementRef}
                        />
                    </label>
                </div>
                <div className={styles.gridItem}>
                    <h4>Cost</h4>
                    <Input type="number" id="cost" name="category" value={this.cost} onChange={this.formChange} min="1" max="10" />
                </div>
                <div className={styles.gridItem}>
                    <h4>Rarity</h4>
                    <label>
                        <Multiselect
                            options={rarityOptions} // Options to display in the dropdown
                            selectedValues={this.raritySelectedValue} // Preselected value to persist in dropdown
                            onSelect={this.rarityOnSelect} // Function will trigger on select event
                            onRemove={this.rarityOnRemove} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            closeIcon="cancel"
                            style={this.style}
                            ref={this.state.rarityRef}
                        />
                    </label>
                </div>
                <div className={styles.gridItem}>
                    <h4>Category</h4>
                    <Input type="text" id="category" name="category" value={this.category} onChange={this.formChange} placeholder="X" />
                </div>
                <div className={styles.gridItem}>
                    <h4>Artist</h4>
                    <label>
                        <Multiselect
                            options={artistOptions} // Options to display in the dropdown
                            selectedValues={this.artistSelectedValue} // Preselected value to persist in dropdown
                            onSelect={this.artistOnSelect} // Function will trigger on select event
                            onRemove={this.artistOnRemove} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            closeIcon="cancel"
                            style={this.style}
                            ref={this.state.artistRef}
                        />
                    </label>
                </div>
                <div className={styles.gridItem}>
                    <h4>Ability</h4>
                    <label>
                        <Multiselect
                            options={abilityOptions} // Options to display in the dropdown
                            selectedValues={this.abilitySelectedValue} // Preselected value to persist in dropdown
                            onSelect={this.abilityOnSelect} // Function will trigger on select event
                            onRemove={this.abilityOnRemove} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            closeIcon="cancel"
                            style={this.style}
                            ref={this.state.abilityRef}
                        />
                    </label>
                </div>
            </div>
            <div id={styles.searchButtons}>
                <button id={styles.clear} onClick={this.clearForm}>Clear</button>
                <button id={styles.card} onClick={this.submitForm}>Get Cards</button>
            </div>

            <SearchResults pagerClick={this.pagerClick} docNum={this.state.docNum} query={this.state.query} cards={this.state.cards} ></SearchResults>
        </div>
    }
}

function constructQuery(state) {
    let query = {}
    for (const element in searchElement){
        let field = searchElement[element]
        let add = false;
        if(typeof(state[field]) === String){
            if(state[field] === ""){
                add = true;
            }
        }else{
            if(state[field].length > 0){
                add = true;
            }
        }

        if(add > 0){
            query[field] = state[field]
        }
    }

    return Object.keys(query).length === 0 ? null : query 
}

const Input = styled.input`
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 5px;
    min-height: 22px;
    position: relative;
`
export default Search;