import React from 'react';
import './App.css';
import Index from './Index/Index.js'

function App() {

  return (
    <div className="App">
      <Index></Index>
    </div>
  );
}

export default App;
