import React, { Component } from 'react';
import styles from './Title.module.css'

class Title extends Component {
  render() {
    return <header id={styles.header}>
      <a href="/"><img id={styles.dawnCrystal} alt="Dawn Crystal Logo" src="/images/DawnCrystalBanner.png"></img></a>
    </header>
  }
}

export default Title;