import React, { Component } from 'react'
import Chart from "chart.js";
import classes from "./LineGraph.module.css";

export default class LineGraph extends Component {

    chartRef = React.createRef();
    
    componentDidUpdate() {
        const myChartRef = this.chartRef.current.getContext("2d");
        let normal = this.props.cardPrices.filter((x) => x.type === "Normal");
        let foil = this.props.cardPrices.filter((x) => x.type === "Foil");

        let normalPrices = normal.map(x => {return x.price})
        let foilPrices = foil.map(x => {return x.price})
        let dates = normal.map(x => {return new Date(x.date).toLocaleDateString()})

        new Chart(myChartRef, {
            type: "line",
            data: {
                //Bring in data
                labels: dates,
                datasets: [
                    {
                        label: "Normal",
                        fillColor: "rgba(255,255,255,1)",
                        strokeColor: "rgba(255,255,255,1)",
                        backgroundColor: "rgba(158,158,158, 0.3)",
                        data: normalPrices,
                    },
                    {
                        label: "Foil",
                        fillColor: "rgba(255,255,255,1)",
                        strokeColor: "rgba(255,255,255,1)",
                        backgroundColor: "rgba(255,255,7, 0.3)",
                        data: foilPrices,
                    }
                ]
            },
            options: {
                legend: {
                    labels: {
                        fontColor: "white"
                    }
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 10
                        }
                    }],
                    // yAxes: [{
                    //     ticks: {
                    //         fontColor: "white",
                    //         beginAtZero: true
                    //     }
                    // }]
                }
            }
        });
    }
    render() {
        return (
            <div className={classes.graphContainer}>
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />
            </div>
        )
    }
}