
import React, { Component } from 'react';
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

class Tooltip extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <Box tooltip={this.props.tooltip}>
            {this.props.pr.map((p) => {
                return <Item> 
                    {p.exp.exec(this.props.password) ?
                    <FontAwesomeIcon icon={faCheckCircle} color="#00ff00" /> :
                    <FontAwesomeIcon icon={faTimesCircle} color="red" />}
                    {p.text}
                </Item>
            })}
        </Box>
    }

}

const Item = styled.p`
    color:white;
    display:block
`

const Box = styled.div`
opacity: ${props => props.tooltip ? "1" : "0"};
	width: 120px;
	background-color: #5f5f5f;
	color: white;
	text-align: left;
	border-radius: 6px;
	position: absolute;
	z-index: 1;
	left: 50%;
    margin-left: -70px;
    padding: 10px;
    bottom: 56%;
    transition: opacity 0.5s;
    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #5f5f5f transparent transparent transparent;
      }
`

export default Tooltip;