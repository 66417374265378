export const setOptions = [
    { name: "Opus I", id: "Opus I" },
    { name: "Opus II", id: "Opus II" },
    { name: "Opus III", id: "Opus III" },
    { name: "Opus IV", id: "Opus IV" },
    { name: "Opus V", id: "Opus V" },
    { name: "Opus VI", id: "Opus VI" },
    { name: "Opus VII", id: "Opus VII" },
    { name: "Opus VIII", id: "Opus VIII" },
    { name: "Opus IX", id: "Opus IX" },
    { name: "Opus X", id: "Opus X" },
    { name: "Opus XI", id: "Opus XI" },
    { name: "Opus XII", id: "Opus XII" },
    { name: "Opus XIII", id: "Opus XIII" },
    { name: "Opus XIV", id: "Opus XIV" },
    { name: "Crystal Dominion", id: "Crystal Dominion" },
    { name: "Boss Deck Chaos", id: "Boss Deck Chaos" },
]

export const typeOptions = [
    { name: "Forward", id: "Forward" },
    { name: "Backup", id: "Backup" },
    { name: "Summon", id: "Summon" },
    { name: "Monster", id: "Monster" }
]

export const elementOptions = [
    { name: "Fire", id: "Fire" },
    { name: "Ice", id: "Ice" },
    { name: "Wind", id: "Wind" },
    { name: "Earth", id: "Earth" },
    { name: "Lightning", id: "Lightning" },
    { name: "Water", id: "Water" },
    { name: "Light", id: "Light" },
    { name: "Dark", id: "Dark" }
]

export const rarityOptions = [
    { name: "Common", id: "C" },
    { name: "Rare", id: "R" },
    { name: "Hero", id: "H" },
    { name: "Legend", id: "L" },
    { name: "Starter", id: "S" },
    { name: "Promo", id: "P" }
]

export const artistOptions = [
    { name: "Tetsuya Nomura", id: "Tetsuya Nomura" },
    { name: "Akihiko Yoshida", id: "Akihiko Yoshida" },
    { name: "Yoshitaka Amano", id: "Yoshitaka Amano" },
    { name: "Tetsuya Nomura/Yasuhisa Izumisawa", id: "Tetsuya Nomura/Yasuhisa Izumisawa" },
    { name: "Tetsuya Nomura/Roberto Ferrari", id: "Tetsuya Nomura/Roberto Ferrari" },
    { name: "Toshiyuki Itahana", id: "Toshiyuki Itahana" },
    { name: "Yasuhisa Izumisawa", id: "Yasuhisa Izumisawa" },
    { name: "Akira Oguro", id: "Akira Oguro" },
    { name: "Toshitaka Matsuda", id: "Toshitaka Matsuda" },
    { name: "Roberto Ferrari", id: "Roberto Ferrari" },
    { name: "Suranaki", id: "Suranaki" },
    { name: "Tsudajiro", id: "Tsudajiro" },
    { name: "Victor", id: "Victor" },
    { name: "Nurikabe", id: "Nurikabe" },
    { name: "Vusc", id: "Vusc" },
    { name: "Vuk Kostic", id: "Vuk Kostic" },
    { name: "Kumiko Koike", id: "Kumiko Koike" },
    { name: "Yu Cheng Hong", id: "Yu Cheng Hong" },
    { name: "Lasahido", id: "Lasahido" },
    { name: "Nagase", id: "Nagase" },
    { name: "Munetaka Ikeda", id: "Munetaka Ikeda" },
    { name: "Rakuen", id: "Rakuen" },
    { name: "ShuichiWada", id: "ShuichiWada" },
    { name: "K-Suwabe", id: "K-Suwabe" },
    { name: "Satoru Senda", id: "Satoru Senda" },
    { name: "Yuu", id: "Yuu" },
    { name: "Zeen Chin", id: "Zeen Chin" },
    { name: "Isamu Kamikokuryo", id: "Isamu Kamikokuryo" },
    { name: "Akihiro Yamada", id: "Akihiro Yamada" },
    { name: "Takayuki Futami", id: "Takayuki Futami" },
    { name: "Rubi Asami", id: "Rubi Asami" },
    { name: "Gen Kobayashi", id: "Gen Kobayashi" },
    { name: "Yusuke Naora", id: "Yusuke Naora" }
]

export const abilityOptions = [
    { name: "Ex Burst", id: "exBurst" },
    { name: "Special", id: "special" },
    { name: "Generic", id: "generic" }
]