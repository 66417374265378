import React, { Component } from 'react';
import styles from './SearchResults.module.css';
import {
    Link
} from "react-router-dom";

class SearchResults extends Component {

    constructor(props){
        super(props)
    }

    render() {
        return <div id={styles.SearchResults}>
            <div className={styles.pager}>
                {getli(this.props.docNum / 20, this.props.pagerClick)}
            </div>
            {this.props.cards && this.props.cards.map((card) => {
                return <span key={card.code + "sp"} className="card">
                    <Link to={`/carddetail/${card.code}`}>
                        <img className={styles.img_result} key={card.code + "img"} alt={card.name} src={"./cards/" + card.code + "_eg.jpg"} title={`${card.code} | ${card.name}`} />
                    </Link>
                </span>
            })}
            <div className={styles.pager}>
                {getli(this.props.docNum / 20, this.props.pagerClick)}
            </div>
        </div>
    }
}

function getli(page, dif) {
    let html = []
    for (let i = 0; i < page; i++) {
        html.push(<li key={i} value={i} onClick={dif} className={styles.pagination}>{i + 1}</li>);
    }

    return html;
}

export default SearchResults;