import React, { Component } from 'react';
import styles from './CardDetails.module.css';
import LineGraph from '../LineGraph/LineGraph.js';

class CardDetails extends Component {
    constructor(props) {
        super(props);
        let href = window.location.href;
        let num = href.split('/');
        let initialState = {
            id: num[num.length - 1],
            card: {
                name: String(),
                type: String(),
                job: String(),
                element: String(),
                cost: String(),
                rarity: String(),
                power: String(),
                category: String(),
                set: String(),
                description: String(),
                pictureLink: String(),
                lang: String,
                cardPrices: [],
                externalLink: {},
                artist: String(),
                code: String(),
                exBurst: Boolean()
            }
        }
        this.state = initialState;
    }

    componentDidMount() {
        fetch("/api/v1/SingleCard", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "code": this.state.id })
        }).then((res) => res.json())
            .then((data) => {
                this.setState({ card: data });
            })
            .catch((err) => {
                console.log("err", err);
            })
    }

    report = () => {
        let data = {
            "message": "There is something wrong with this card",
            "cardID": this.state.card.code,
            "url": window.location.href,
            "user": "Guest"
        }
        fetch("/api/v1/reportCard", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }).then((res) => res.json())
            .then((data) => {
                if(data === 201 || data === 400){
                    alert("Thank you for reporting this card");
                }
                else{
                    alert("Report not sent to due to error")
                }
            })
            .catch((err) => {
                console.log("err", err);
            })
    }

    addCard = (id) => {
        console.log(`Removing ${id}`);
        let data = {
            id
        }
        fetch("/api/v1/addUserCard", {
            method: "POST",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.props.token}`
            },
            body: JSON.stringify(data)
        })
        .then((res) => {
            return res.text()
            
        })
        .then((data) => {
            console.log(data)
        })
        .catch((err) => {
            console.log("err", err);
        })
    }

    render() {

        let buyCard = null
        if(this.state.card.externalLink){
        buyCard = <div className={styles.gridItem} id={styles.item5}>
                    <h3>Buy This Card At</h3>
                    <a id={styles.tcg_anchor} href={`${this.state.card.externalLink.TCG}`} target="_blank" rel="noopener noreferrer"><button id={styles.tcg_button}>TCGplayer</button></a>
                </div>
        }

        return (
            <div>
                <div id={styles.flexBox}>
                <span className={styles.flex} id={styles.leftflex}>
                    <div>
                        {
                           this.state.card.code 
                           ? <img src={`/cards/${this.state.card.code}_eg.jpg`} alt={`${this.state.card.code}`} id={styles.pic} />
                           : <></>
                        }
                    </div>
                </span>
                <span className={styles.flex}>
                    <div className={styles.gridItem} id={styles.item3}>
                        <div><sup>Normal</sup> ${this.state.card.cardPrices[0] && getLatestPrice("Normal", this.state.card.cardPrices)}</div>
                        <div><sup>Foil</sup> ${this.state.card.cardPrices[0] && getLatestPrice("Foil", this.state.card.cardPrices)}</div>
                    </div>
                    <div className={styles.gridItem} id={styles.item2} >
                        {this.state.card.name && <div><h3>Name:</h3> <p>{this.state.card.name}</p> </div>}
                        {this.state.card.type && <div><h3>Type:</h3> <p>{this.state.card.type}</p> </div>}
                        {this.state.card.job && <div><h3>Job:</h3> <p>{this.state.card.job}</p> </div>}
                        {this.state.card.element && <div><h3>Element:</h3> <p>{this.state.card.element.map((el) => {return <span class={"icon " + el.toLowerCase()} />})}</p> </div>}
                        {this.state.card.cost > 0 && <div><h3>Cost:</h3> <p>{this.state.card.cost}</p> </div>}
                        {this.state.card.rarity && <div><h3>Rarity:</h3> <p>{this.state.card.rarity}</p> </div>}
                        {this.state.card.power > 0 && <div><h3>Power:</h3> <p>{this.state.card.power}</p> </div>}
                        {this.state.card.category && <div><h3>Category:</h3> <p>{this.state.card.category}</p> </div>}
                        {this.state.card.set && <div><h3>Set:</h3> <p>{this.state.card.set}</p> </div>}
                        {this.state.card.description && <div><h3>Description:</h3> <p dangerouslySetInnerHTML={{ __html: this.state.card.description }}></p> </div>}
                        {this.state.card.artist && <div><h3>Artist:</h3> <p>{this.state.card.artist}</p> </div>}
                    </div>
                    <div className={styles.gridItem} id={styles.item4}>
                        <h3>Add card to Portfolio</h3>
                        {
                            this.props.token
                            ? <button id={styles.add_card} onClick={() => {this.addCard(this.state.card._id)}}>+</button>
                            : <button id={styles.add_card_disabled} disabled>+</button>
                        }
                    </div>
                    {buyCard}
                </span>
                </div>
                <div id={styles.graphdiv}>
                    <LineGraph cardPrices={this.state.card.cardPrices}/>
                </div>
                <div id={styles.reportBox}>
                    <p>Something Amiss? </p><button id={styles.report} onClick={this.report}>Report</button>
                </div>
            </div>
        )
    }
}

function getLatestPrice(str, prices) {
    let ar = prices.filter(x => x.type === str);
    if (ar.length > 0) {
        return ar[ar.length - 1].price;
    }
}

export default CardDetails;