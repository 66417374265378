import React, { useState, useEffect } from 'react';
import styles from './Portfolio.module.css';
import styled from 'styled-components';
import {
    Link,
    useHistory
} from "react-router-dom";

function Portfolio( {token} ) {
    const [cards, setCards] = useState([]);
    const history = useHistory();

    useEffect(() => {
        fetch("/api/v1/getUserCards", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => {
            return response.json()
        }).then((json) => {
            setCards(json.cards);
        })
    }, [token, setCards])

    useEffect(() => {
        if(!token){
            history.push("/");
        }
    }, [token, history])

    function removeCard(id){
        console.log(`Removing ${id}`);
        let data = {
            id
        }
        fetch("/api/v1/removeUserCard", {
            method: "POST",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        .then((res) => {
            if(res.ok){
                console.log("great job");
                const newCards = cards.filter((card) => {
                    return card._id !== id
                })
                setCards(newCards);
            }
        })
        .catch((err) => {
            console.log("err", err);
        })
    }    

    return (
        <nav id={styles.nav}>      
            {
                cards.length > 0
                ? cards.map((card) => {
                    return <FlexBox>
                        <Link to={`/carddetail/${card.code}`}><Pic src={"./cards/" + card.code + "_eg.jpg"} href=""/></Link>
                        <Info>
                            <Header>{card.code}<Remove onClick={() => removeCard(card._id)}>X</Remove></Header>
                            <h2>
                                {card.cardPrices.filter((price) => { return price.type === "Normal"})[0].price}
                                <Super>Normal</Super>
                            </h2>
                            <h2>
                                {card.cardPrices.filter((price) => { return price.type === "Foil"})[0].price}
                                <Super>Foil</Super>
                            </h2>
                        </Info>
                    </FlexBox>
                })
                : <h1>No cards to display</h1>
            } 
        </nav>
    )
}

const Remove = styled.span`
    float: right;
    margin-right: 1rem;
    cursor: pointer;
`

const Header = styled.h1`
    border-bottom: 1px solid white;
`

const Info = styled.div`
    flex-grow: 1;
`

const Super = styled.div`
    vertical-align:super;
    display:inline;
    font-size: 1rem;
`
const Pic = styled.img`
    height: 300px;
    border-radius: 8px;
`

const FlexBox = styled.div`
    display: flex;
    margin: 1rem auto 1rem auto;
    justify-content: left;
    border-color: white;
    border-style: solid double solid double;
    border-width: 3px;
    border-radius: 15px;
    max-width: 50rem;
    min-width : 30rem;
`

export default Portfolio;