
import React, { Component } from 'react';
import styles from './Learn.modules.css'

class Learn extends Component {

    render() {
        return (
            <div>
                <a className="anchor" href="https://fftcg.cdn.sewest.net/2020-02/fftcgrulesheet-en.pdf">
                    <div className="container">
                        <div>
                            <h2>The Basics</h2>
                            <p>Learn the basics and begin playing today!</p>
                        </div>
                        <img alt="Learn To Play" className="image" src="../images/starterpackrules.png"></img>
                    </div>
                </a>
                <a className="anchor" href="https://fftcg.cdn.sewest.net/COmp%20Rules%20Latest/fftcg-opus-cr-english-20200423.pdf">
                    <div className="container">
                        <div>
                            <h2>Advanced Rules</h2>
                            <p>You have the basics down and now you need more.</p>
                        </div>
                        <img alt="Advanced Rules" className="image" src="../images/advancedrules-v3.png"></img>
                    </div>
                </a>
                <a className="anchor" href="https://fftcg.cdn.sewest.net/rules/fftcggamemat-en.pdf">
                    <div className="container">
                        <div>
                            <h2>Starter Decks & Mat</h2>
                            <p>Get a jump start with pre-built decks and a play mat.</p>
                        </div>
                        <img alt="Deck ideas & mat" className="image" src="../images/decksandmats.png"></img>
                    </div>
                </a>
                <a className="anchor" href="https://fftcg.square-enix-games.com/na/gameplay#tutorial">
                    <div className="container">
                        <div>
                            <h2>Online Tutorial</h2>
                            <p>Play the online tutorial to get the hang of things.</p>
                        </div>
                        <img alt="Game tutorial" className="image" src="../images/Tutorial.png"></img>
                    </div>
                </a>
            </div>
        )
    }
}

export default Learn;