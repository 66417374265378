
import React, { Component } from 'react';
import styles from './Footer.module.css'

class Footer extends Component {
    render() {
        return (
            <div>
                <hr/>
                <p>Timothy Perry. 2020. Dawn Crystal &trade;.</p>
            </div>
        )
    }
}

export default Footer;